<template>
  <div class="current-projects-item">
    <q-card class="my-card">
      <router-link :to="'/project/' + project.projectId">
        <div
          class="line"
          :style="{ '--project-color': project.projectColor }"
        ></div>
        <q-card-section>
          <div class="title">{{ project.projectName }}</div>
          <div class="description">{{ project.projectDescription }}</div>
        </q-card-section>

        <q-separator dark />

        <q-card-section>
          <div class="date">màj. {{ project.updatedOn | formattingDate }}</div>
        </q-card-section>
      </router-link>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'CurrentProjectsItem',
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
