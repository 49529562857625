<template>
  <div :class="{ home: true, 'is-close-messages': !getIsOpen }">
    <q-page-container>
      <q-page>
        <div class="column" style="height: calc(100vh - 86px)">
          <div>
            <CurrentProjects />
          </div>
          <div>
            <TableDocumentsBlock
              title="Documents récents"
              :is-action-see-all="true"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>

    <Messages />
  </div>
</template>

<script>
import CurrentProjects from '@/components/home/CurrentProjects.vue'
import Messages from '@/components/global/Messages.vue'
import { mapGetters } from 'vuex'
import TableDocumentsBlock from '@/components/documents/TableDocumentsBlock'

export default {
  name: 'MainHome',
  components: {
    TableDocumentsBlock,
    Messages,
    CurrentProjects
  },
  computed: {
    ...mapGetters('messages', ['getIsOpen'])
  }
}
</script>
