<template>
  <div class="current-projects">
    <div class="top">
      <div class="title">Projets en cours</div>
      <div class="action">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Nouveau projet"
          @click="$router.push('/new-project')"
        />
        <q-btn
          :class="{ 'btn-see': true, disabled: !getLastProjects.length }"
          icon-right="trending_flat"
          label="Voir les projets"
          @click="$router.push('/projects')"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="row" v-if="getLastProjects.length">
        <CurrentProjectsItem
          v-for="item in getLastProjects"
          :key="item.projectId"
          :project="item"
          class="col-12 col-md-6 col-lg-3"
        />
      </div>
      <LoadingOrEmpty
        v-else
        :loading="getLoadingLastProjects"
        :custom-empty="true"
      >
        <template v-slot:customEmpty>
          <div class="no-documents">Aucun projet en cours</div>
        </template>
      </LoadingOrEmpty>
    </div>
  </div>
</template>

<script>
import CurrentProjectsItem from '@/components/home/CurrentProjectsItem.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    LoadingOrEmpty,
    CurrentProjectsItem
  },
  created() {
    this.fetchLastProjects()
  },
  computed: {
    ...mapGetters('projects', [
      'getLastProjects',
      'getLoadingLastProjects',
      'getErrorLastProjects'
    ])
  },
  methods: {
    ...mapActions('projects', ['fetchLastProjects']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorLastProjects: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
